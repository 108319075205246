import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { theme } from './components/other/helper/MuiStyles';
import Header from './components/Header/desktop/Header';
import HeaderMobile from './components/Header/mobile/HeaderMobile';
import Footer from './components/Footer/desktop/Footer';
import FooterMobile from './components/Footer/mobile/FooterMobile';
import { GeneralProvider } from './components/other/context/GeneralContext';
import About from './pages/About/desktop/About';
import AboutMobile from './pages/About/mobile/AboutMobile';
import ProjectDetails from './pages/ProjectDetails/desktop/ProjectDetails';
import ProjectDetailsMobile from './pages/ProjectDetails/mobile/ProjectDetailsMobile';
import FullViewPage from './pages/FullViewPage/desktop/FullViewPage';
import TileViewPage from './pages/TileViewPage/desktop/TileViewPage';
import FullViewPageMobile from './pages/FullViewPage/mobile/FullViewPageMobile';
import TileViewPageMobile from './pages/TileViewPage/mobile/TileViewPageMobile';

function App() {

  const [device, setDevice] = useState((window.innerWidth <= 750) ? 'mobile' : 'desktop');  
  
  useEffect(() => {
    function handleResize() {
      switch (true) {
        case window.innerWidth <= 750:
          setDevice('mobile');
          break;
        case window.innerWidth > 750:
          setDevice('desktop');
          break;
        default:
          setDevice('desktop');
          break;
      }
    }
    
    window.addEventListener('resize', handleResize)
    
  })

  return (
      <GeneralProvider>
      <Router>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            {device === 'desktop' ? <Header /> : <HeaderMobile />}
            <Routes>
              <Route path="/tile" element={device === 'desktop' ? <TileViewPage /> : <TileViewPageMobile />} />
              <Route path="/about" element={device === 'desktop' ? <About /> : <AboutMobile />} />
              <Route path="/project/:id" element={device === 'desktop' ? <ProjectDetails /> : <ProjectDetailsMobile />} />
              <Route exact path="/" element={device === 'desktop' ? <FullViewPage /> : <FullViewPageMobile />} />
            </Routes>
            {device === 'desktop' ? <Footer /> : <FooterMobile />}
          </ThemeProvider>
        </StyledEngineProvider>
      </Router>
    </GeneralProvider> 
  );
}

export default App;
