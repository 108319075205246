import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { GeneralContext } from '../../other/context/GeneralContext';
import { useStyles } from './Header.styles';
import { brown } from '../../other/helper/MuiStyles';

export default function Header() {

    const location = useLocation();
    const { isFilterSet, setIsFilterSet, isTileViewSelected, setIsTileViewSelected } = useContext(GeneralContext);

    const styleProps = {
        headerBackgroundColor: location.pathname === '/about' ? brown : '',
    };

    const classes = useStyles(styleProps);

    return (
        <Box px={'20px'} pt={((!isTileViewSelected && location.pathname === '/')) ? [isFilterSet && !isTileViewSelected ? 'calc(6vh - 39px)' : '6vh'] : [location.pathname.includes("project") ? '6vh' : '0px']} display="flex" justifyContent="space-between" className={classes.header_box}>
            <Box display="flex" alignItems="center">
                <Button
                    disableRipple
                    variant="text"
                    component={Link}
                    to={`/`}
                    className={classes.button_carla}>
                    CARLA MARIA PECHO,
                </Button>
                {(location.pathname === '/' || location.pathname === '/tile') &&
                    <>
                        <Typography variant="body1" className={classes.button_group}>projects:</Typography>
                        <Button
                            disableRipple
                            variant="text"
                            component={Link} to={`/tile`}
                            onClick={() => setIsFilterSet(!isFilterSet)}>
                            filter/menu,
                        </Button>
                        <Button
                            disableRipple
                            component={Link} to={`/`}
                            variant="text"
                            onClick={() => setIsTileViewSelected(false)}>
                            full-width,
                        </Button>
                        <Button
                            disableRipple
                            component={Link} to={`/tile`}
                            variant="text"
                            onClick={() => setIsTileViewSelected(true)}>
                            tile-view
                        </Button>
                    </>
                }
            </Box>
            <Box display="flex" alignItems="center">
                {!(location.pathname === '/tile' || location.pathname === '/') &&
                    <Button
                        disableRipple
                        variant="text"
                        component={Link}
                        to={`/`}
                        onClick={() => setIsTileViewSelected(false)}
                        >
                        projects
                    </Button>
                }
                <Button
                    disableRipple
                    variant="text"
                    href="mailto:studio@carlamariapecho.com"
                >
                    contact
                </Button>
                <Button
                    disableRipple
                    variant="text"
                    component={Link}
                    to={`/about`}
                    onClick={() => setIsTileViewSelected(false)}
                >
                    about
                </Button>
            </Box>
        </Box>
    )
}