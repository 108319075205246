import makeStyles from "@mui/styles/makeStyles";
import { blue } from "../../other/helper/MuiStyles";

export const useStyles = makeStyles(() => ({    
    
    link: {
        letterSpacing: '0.08em',
        color: blue,
        textDecoration: 'underline',
        padding: '0px',
        '&:hover': {
            fontStyle: 'italic',
            textDecoration: 'underline',
        },
        '&:focus': {
            fontStyle: 'italic',
        },
        '&:active': {
            fontStyle: 'italic',
        },
    },

}));