import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { useStyles } from './HeaderMobile.styles';
import { brown } from '../../other/helper/MuiStyles';
import { GeneralContext } from '../../other/context/GeneralContext';

export default function HeaderMobile() {

    const location = useLocation();
    const { isFilterSet, setIsFilterSet, setIsTileViewSelected } = useContext(GeneralContext);

    const styleProps = {
        headerBackgroundColor: location.pathname === '/about' ? brown : '',
    };

    const classes = useStyles(styleProps);


    return (
        <Box px={'20px'} pt={'calc(6vh - 39px)'} height={(location.pathname === '/' || location.pathname === '/tile') ? '10vh' : '25px'} className={classes.header_box}>

            <Box display="flex" alignItems="center" className={classes.top_header_box}>
                <Button
                    disableRipple
                    variant="text"
                    component={Link}
                    to={`/`}
                    className={classes.button_carla}>
                    CARLA MARIA PECHO,
                </Button>
                <Box display="flex" alignItems="center" className={classes.nav_box}>
                    {!(location.pathname === '/tile' || location.pathname === '/') &&
                        <Button
                            disableRipple
                            variant="text"
                            component={Link}
                            to={`/`}>
                            projects
                        </Button>
                    }
                    <Button
                        disableRipple
                        variant="text"
                        href="mailto:studio@carlamariapecho.com"
                    >
                        contact
                    </Button>
                    <Button
                        disableRipple
                        variant="text"
                        component={Link}
                        to={`/about`}
                    >
                        about
                    </Button>
                </Box>

            </Box>

            <Box className={classes.sub_nav_box}>
                {(location.pathname === '/' || location.pathname === '/tile') &&
                    <>
                        <Typography variant="body1" className={classes.button_group}>projects:</Typography>
                        <Button
                            disableRipple
                            variant="text"
                            component={Link} to={`/tile`}
                            onClick={() => setIsFilterSet(!isFilterSet)}>
                            filter/menu,
                        </Button>
                        <Button
                            disableRipple
                            component={Link} to={`/`}
                            variant="text"
                            onClick={() => setIsTileViewSelected(false)}>
                            full-width,
                        </Button>
                        <Button
                            disableRipple
                            component={Link} to={`/tile`}
                            variant="text"
                            onClick={() => setIsTileViewSelected(true)}>
                            tile-view
                        </Button>
                    </>
                }
            </Box>
        </Box>
    )
}