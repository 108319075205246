import makeStyles from "@mui/styles/makeStyles";
import { blue } from "../helper/MuiStyles";

export const useStyles = makeStyles(() => ({    
    close_btn: {
        position: 'absolute',
        padding: '0px',
    },
     dlg_content: {
        backgroundColor: props => props.imprintBackgroundColor,
    },
    link: {
        letterSpacing: '0.08em',
        color: blue,
        textDecoration: 'underline',
        padding: '0px',
        '&:hover': {
            fontStyle: 'italic',
            textDecoration: 'underline',
        },
        '&:focus': {
            fontStyle: 'italic',
        },
        '&:active': {
            fontStyle: 'italic',
        },
    },
}));