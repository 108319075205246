import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './FullScreenBox.styles';
import { IMAGE_MAP } from '../../other/helper/ImgMapper';

export default function FullScreenBox({ project }) {

    const ref = useRef(null);
    const ref2 = useRef(null);
    const [isShown, setIsShown] = useState(false);
    let value = 'none';

    isShown ? value = 'block' : value = 'none';

    useEffect(() => {
        const cursor_scrll = ref.current;
        const wrapper = ref2.current;

        const onMouseMove = (e) => {
            cursor_scrll.style.transition = 'linear';
            cursor_scrll.style.left = (e.pageX) + 'px';
            cursor_scrll.style.top = (e.pageY) + 'px';
        }

        wrapper.addEventListener('mousemove', onMouseMove)

    }, []);

    const classes = useStyles();

    return (
        <Box ref={ref2} display="flex" className={classes.full_view_wrapper} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
            <Typography ref={ref} display={value} position="absolute" zIndex="100010">SCROLL 
            &#8593;
            {/* &#8595; DOWNARROW */}
            </Typography>
            {IMAGE_MAP.DESKTOP_FULL[project.pictures[0]]?.includes("video") ?
                <Box position="relative">
                    <div className={classes.video_overlay}></div>
                    <div>
                        <div px="20px" className={classes.project_video_wrapper}>
                            <iframe src={`https://player.vimeo.com/${IMAGE_MAP.DESKTOP_FULL[project.pictures[0]]}?quality=1080p&background=1&dnt=1`}
                                className={classes.project_video}
                                frameBorder="0"
                                title="movie"
                                
                            >
                            </iframe>
                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                    </div>
                </Box>
                :
                
                <Box className={classes.full_view_picture} style={{ backgroundImage: `url(${IMAGE_MAP.DESKTOP_FULL[project.pictures[0]]})` }}>
                </Box>
            }
        </Box>
    )
}