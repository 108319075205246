import React from "react";

import { ReactComponent as MinusIcon } from './minus.svg';
import { ReactComponent as PlusIcon } from './plus.svg';

const icons = {
    MinusIcon: MinusIcon,
    PlusIcon: PlusIcon,
}

export default function Icon(props) {
    const MyIcon = icons[props.iconName];
    return <MyIcon width={props.width ? props.width : 10} height={props.height ? props.height : 10}/>;
}