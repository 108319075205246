import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(() => ({    
    project_box: {
        textDecoration: 'none',
    },
    small_project_picture: {
        backgroundImage: props => `url(${props.smallProjectPicture})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',        
        backgroundPosition: 'center center',
        borderRadius: '0px',
        width: 'calc(100vw / 5)',
        height: 'calc(100vw / 5)',
    }
}));