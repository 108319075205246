import { createTheme } from '@mui/material/styles';

export const black = '#181818';
export const grey150 = '#D0D0D0';
export const grey100 = '#EBEBEB';
export const grey50 = '#F5F5F5';
export const blue = '#0023DB';
export const brown = '#E3E4E5';

export const fontSize = '11.3px';
export const lineHeight = '18px';
export const innerHeightBig = `calc(100vh - 21px - ${lineHeight} - 6vh - 5vh)`;
export const innerHeightFilterBig = `calc(100vh - 21px - ${lineHeight} - 6vh - 5vh + (6vh - 39px))`;
export const innerHeightSmall = `calc(100vh - 5vh - ${lineHeight} - 21px)`;
// export const innerHeightMobile = `calc(100vh - 6vh - 39px - 5vh)`;
export const innerHeightMobile = `calc(85vh - 5vh - 6vh - 39px)`;
export const innerHeightMobileFilterSet =  `calc(100vh - 6vh - 39px - 5vh - ${lineHeight})`
// maybe has to change when pc or mobile device


export let theme = createTheme();


theme = createTheme(theme, {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'pragmatica',
                    fontSize: fontSize,
                    lineHeight: lineHeight,
                    fontWeight: '400',
                    minWidth: '0px',
                    letterSpacing: '0.065em',
                    textTransform: 'none',
                    textAlign: 'center',  
                    backgroundColor: 'rgba(255, 255, 255, 0)',
                    overflow: 'auto',
                },
                contained: {
                    lineHeight: '8px',
                    borderRadius: '0px',
                    padding: '7px 16px',
                    backgroundColor: '#FFF',
                    textTransform: 'lowercase',
                    boxShadow: '4px 4px 3px rgba(126, 126, 126, 0.04)',
                    // border: `1px solid #f5f5f5`,
                    color: blue,
                    '&:hover': {
                        backgroundColor: 'white',
                        boxShadow: 'inset 4px 4px 3px rgba(126, 126, 126, 0.06)',
                    },
                    // '&:focus': {
                    //     backgroundColor: grey50,
                    //     boxShadow: '4px 4px 3px rgba(126, 126, 126, 0.06)',
                    // },
                    '&:active': {
                        backgroundColor: grey50,
                        boxShadow: '4px 4px 3px rgba(126, 126, 126, 0.06)',
                    },                           
                },
                text: {
                    lineHeight: lineHeight,
                    color: black,
                    padding: '0 0 0 20px',   
                    transition: 'none',                 
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0)',
                        fontStyle: 'italic',
                    },
                    '&:focus': {
                        backgroundColor: 'rgba(255, 255, 255, 0)',
                        fontStyle: 'italic',
                    },
                    '&:active': {
                        backgroundColor: 'rgba(255, 255, 255, 0)',
                        fontStyle: 'italic',
                    },
                    // find prop for 'clicked'
                },
                outlined: {
                    lineHeight: '8px',
                    borderRadius: '0px',
                    border: '1px',
                    padding: '7px 16px',
                    backgroundColor: grey50,
                    textTransform: 'lowercase',
                    // boxShadow: '4px 4px 3px rgba(126, 126, 126, 0.1)',
                    color: black,
                    '&:hover': {
                        lineHeight: '8px',
                        border: '0px',
                        backgroundColor: 'white',
                        color: black,
                    },
                    // '&:focus': {
                    //     lineHeight: '8px',
                    //     border: '0px',
                    //     backgroundColor: grey50,
                    //     color: black,
                    // },
                    '&:active': {
                        lineHeight: '8px',
                        border: '0px',
                        backgroundColor: grey50,
                        color: black,
                    },              
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingLeft: '21px',
                    minHeight: innerHeightBig,
                    msOverflowStyle: 'none', /* IE and Edge */
                    scrollbarWidth: 'none', /* Firefox */
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    }
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    backgroundColor: 'none',
                }
            }
        },


        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: 'pragmatica',
                    fontWeight: '400',
                    color: black,
                    fontSize: fontSize,
                    lineHeight: lineHeight,
                },
                // HEAD
                h1: {
                    letterSpacing: '0.05em',
                    textTransform: 'uppercase',
                },
                // SELECTED
                h2: {
                    fontStyle: 'italic',
                    letterSpacing: '0.07em',
                },
                // BODY
                body1: {
                    letterSpacing: '0.08em',
                },
                // BODY Blue
                body2: {
                    letterSpacing: '0.07em',
                    color: blue,
                    textTransform: 'lowercase',
                },
                // LINK
                caption: {
                    letterSpacing: '0.08em',
                    color: blue,
                    textDecoration: 'underline',
                },
            }
        }
    }
});