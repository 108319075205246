import makeStyles from "@mui/styles/makeStyles";
import { grey50 } from "../../other/helper/MuiStyles";

export const useStyles = makeStyles(() => ({   
    full_view_picture: {
        maxHeight: props => props.innerHeightMobileTest,
        height: props => props.innerHeightMobileTest,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',        
        backgroundPosition: 'center center',
        width: '100vw',
        opacity: '1',
    },
    full_view_wrapper: {
        width: 'auto',
        maxHeight: props => props.innerHeightMobileTest,
        height: props => props.innerHeightMobileTest,
        cursor: 'none',        
    },
    project_video: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '100vw',
        height: '100vh',
        paddingLeft: '50px',
        paddingRight: '50px',
        transform: 'translate(-50%, -50%)',
    },  
    project_video_wrapper: {
        position: 'relative',
        overflow: 'hidden',
        height: props => props.innerHeightMobileTest,
        width: '100vw',
        backgroundColor: grey50
    }, 
    video_overlay: {
        position: 'absolute',
        zIndex: '10000',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        height: '100%',
        width: '100%',
        opacity: '1%',
        transition: '.1s ease',
        backgroundColor: grey50,
    },
}));