import React, { useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './TileProjectBoxMobile.styles';
import { IMAGE_MAP } from '../../other/helper/ImgMapper';

export default function TileProjectBoxMobile({ project }) {

    const ref = useRef(null);
    const [availableWidthCustomer, setAvailableWidthCustomer] = useState(0);

    const styleProps = {
        tileViewPicture: IMAGE_MAP.MOBILE_FULL[project.pictures[0]],
        availableWidthCustomer: availableWidthCustomer+'px',
    }

    window.requestAnimationFrame(function () {
        setAvailableWidthCustomer(ref.current?.clientWidth - 30 - 24);
    });
    
    const classes = useStyles(styleProps);

    return (
        <Box className={classes.project_box} >
            <Box className={classes.tile_container}>
                <Box className={classes.tile_view_picture} style={{ backgroundImage: `url(${IMAGE_MAP.MOBILE_TILE[project.pictures[0]]})` }} />
                <Box ref={ref} display="flex" alignItems="flex-end" justifyContent="space-between" className={classes.tile_overlay} title={project.customer}>
                    <Box pl='8px'>
                        <Typography variant="body2" className={classes.customer}>
                            {project.customer}
                        </Typography>
                    </Box>
                    <Box pr='8px' display="flex" justifyContent="flex-end" className={classes.year}>
                        <Typography variant="body2">
                            {project.year}
                        </Typography>
                    </Box>
                </Box>
                </Box>
                
            </Box>
    )
}