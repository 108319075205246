import React from 'react';
import { Box, Typography } from '@mui/material';
import Imprint from '../../other/dialogs/Imprint';

export default function FooterMobileException() {

    return (
        <Box px={'21px'} display="flex" alignItems="center" bottom="0" minWidth="calc(100vw - 42px)" justifyContent="space-between" style={{ height: '50px'}}>
            <Typography variant="body1">© 2024</Typography>
            <Imprint buttonName={"imprint"} />
        </Box>
    )
}