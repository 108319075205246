import makeStyles from "@mui/styles/makeStyles";


export const useStyles = makeStyles(() => ({
    paper: {
        overflow: 'auto',
    },

    tile_view_wrapper: {
        maxWidth: '100vw',
        overflow: 'auto',
        msOverflowStyle: 'none', /* IE and Edge */
        scrollbarWidth: 'none', /* Firefox */
        '&::-webkit-scrollbar': {
            display: 'none',
        }
    },

    filter_wrapper: {
        position: 'relative',
    },

    filter_container: {
        position: 'absolute',
        right: '0',
        maxWidth: 'calc(100vw - 33px - 42px)',
        overflow: 'auto',
        whiteSpace: 'nowrap',
        msOverflowStyle: 'none', /* IE and Edge */
        scrollbarWidth: 'none', /* Firefox */
        '&::-webkit-scrollbar': {
            display: 'none',
        }
    },
}));