import React, { useContext } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Imprint from '../../other/dialogs/Imprint';
import { useLocation } from 'react-router-dom';
import { brown } from '../../other/helper/MuiStyles';
import { GeneralContext } from '../../other/context/GeneralContext';
import { useStyles } from './Footer.styles';

export default function Footer() {

        const { cookiesAccepted, setCookiesAccepted } = useContext(GeneralContext);
        const location = useLocation();
        const classes = useStyles();

        return (
                <>
                        {!cookiesAccepted &&
                                <Box p={3} display="flex" alignItems="start" justifyContent="space-between" style={{ position: 'absolute', bottom: '5vh', left: '0', zIndex:'500', width: 'calc(100vw - 48px)', height: 'auto', backgroundColor: 'white' }}>
                                        <Box width="60vw">
                                                <Typography variant="body1">
                                                        This website may uses cookies, which are necessary for its functioning and required to achieve the purposes illustrated in the
                                                        Datenschutzerklärung.
                                                        You accept the possible use of cookies by closing or dismissing this notice, by scrolling this page, by clicking a link or button or by continuing to browse
                                                        otherwise.
                                                </Typography>
                                                <Imprint buttonName={"Learn more"} />
                                        </Box>
                                        <Button
                                                disableRipple
                                                onClick={() => setCookiesAccepted(true)}
                                                className={classes.link}
                                                >
                                                accept + close
                                        </Button>
                                </Box>
                        }
                        <Box px={3} display="flex" alignItems="center" justifyContent="space-between" style={{ height: '5vh', backgroundColor: location.pathname === '/about' ? brown : '' }}>
                                <Typography variant="body1">© 2024</Typography>
                                <Imprint buttonName={"imprint"} />
                        </Box>

                </>
        )
}