import React, { useEffect, useRef } from 'react';
import { Box, Link, Typography } from '@mui/material';
import { useStyles } from './AboutMobile.styles';
import { IMAGE_MAP } from '../../../components/other/helper/ImgMapper';
import { CLIENT_LIST } from '../../../data/data';

export default function AboutMobile() {

    const classes = useStyles();
    const ref = useRef(null);

    let vh = window.innerHeight;
    let vhviewport = vh - 50;

    window.onresize = function () {
        vh = window.innerHeight;
        vhviewport = vh - 50;
    }
    window.onresize();

    useEffect(() => {
        const img_about = ref.current;
        const onMouseMove = (e) => {
            img_about.style.transition = 'left 1s ease-out, right 1.5s ease-out, top 1.3s ease-out, opacity 0.5s';
            img_about.style.opacity = '1';
            img_about.style.left = (e.pageX - 80) + 'px';
            img_about.style.top = (e.pageY - 20) + 'px';
        }
        window.addEventListener('mousemove', onMouseMove);

    }, []);

    return (
        <Box px={'20px'} display="flex" flexDirection="column" justifyContent="center" align-items="end" height={vhviewport} className={classes.paper}>
            <Box>
                <img ref={ref} className={classes.about_img} src={IMAGE_MAP.ABOUT.about} alt={"Carla Pecho"} style={{ opacity: '1' }} />
                <Box display="flex" flexDirection="column" justifyContent="center" className={classes.text_box}>
                    <Typography pb={'20px'}>
                    Working multidisciplinary, mainly in graphic design and art direction, I focus on creating classic yet distinctly contemporary and unconventional outcomes.
                    </Typography>
                    <Typography>
                    Selected Clients
                    </Typography>
                    {CLIENT_LIST.map((option, index) => {
                        return (
                            <Typography key={index} variant="body1">
                                {option.name} - {option.location}
                            </Typography>
                        )
                    })}
                    <Typography pt={'20px'}>
                        Contact: <Link href="mailto:studio@carlamariapecho.com">studio@carlamariapecho.com</Link>,
                    </Typography>
                    <Typography>
                        Social Media: <Link href='https://www.instagram.com/carlamariapecho/'>carlamariapecho</Link>, <Link href='https://www.instagram.com/3118121__/'>3118121__</Link>
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}