import makeStyles from "@mui/styles/makeStyles";
import { grey100, grey50 } from "../../other/helper/MuiStyles";

export const useStyles = makeStyles(() => ({    
    customer: {
        width: props => props.availableWidthCustomer,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    }, 
    project_box: {
        textDecoration: 'none',
    },
    tile_container: {
        position: 'relative',
        width: '50vw',
        // height: `calc((${innerHeightMobile})/10)`,
        height: '48vh',
        backgroundColor: grey50,
    },
    tile_overlay: {
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        height: '100%',
        width: '100%',
        opacity: '0',
        transition: '.1s ease',
        backgroundColor: grey100,
        '&:hover': {
            opacity: '1',
        },
        '&:active': {
            opacity: '1',
        },
    },
    tile_view_picture: {
        opacity: '1',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '50vw',
        height: '48vh',
    },
    year: {
        width: '30px',
    }
}));