import makeStyles from "@mui/styles/makeStyles";
import { brown, innerHeightSmall } from "../../../components/other/helper/MuiStyles";


export const useStyles = makeStyles(() => ({    
    paper: {
        minHeight: innerHeightSmall,
        backgroundColor: brown,
    },
    paragraph: {
        maxWidth: '300px',
    },

    about_img: {
        position: 'absolute',
        maxWidth: '9vw',
        // left: '1px',
        // right: '1px',
        transform: 'translate(-50%,-50%)'
    },

}));