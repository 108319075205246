export const PROJECTS = [
    {
        projectId: '1',
        type: ['1', '4', '10'],
        customer: 'Soulfood Society',
        location: 'Essen',
        year: 2022,
        description: 'The wordmark is set in a timeless and clear-shaped custom type, specially created to capture the modernity and high standards of the restaurant. The openness and cleanness of the wordmark stand in complementary harmony with the figurative mark, which features a visual merge of the glyphs and numbers \'SFS 22\'. The number 22 is of dual significance here: representing the year of establishment and the founder\'s age.',
        pictures: ['soulfood1', 'soulfood3', 'soulfood4', 'soulfood5'],
        
    },    
    {
        projectId: '2',
        type: ['1', '2', '4', '6', '7', '8', '10'],
        customer: 'FRED STUDIOS',
        location: 'Munich',
        year: 2020,
        collaborators: [
            {
                role: 'Project Lead',
                name: 'Jürgen Gessler'
            },
            {
                role: 'Photography',
                name: 'Nicolas Völkl'
            },
            {
                role: 'Editor',
                name: 'Luna Dolkhani'
            },
        ],
        description: 'FRED STUDIOS evolves from an obsolete fitness studio to a more technology-driven and holistic experience. The frilless visual identity is creating curiosity by being unobtrusive. Not defining `perfection` in a one-wayed sense that leaves no room for individual routes is being communicated through the double-sided arrow. The studio highlights a contemporary approach that appreciates each of its member`s respective goals. Accordingly, the imagery concentrates solely on the body and refrains from showing faces for people to identify better with the depicted person. Lifestyle objects further strengthen the bond with the community.',
        pictures: ['fred1', 'fred2', 'fred3', 'fred4', 'fred5', 'fred6', 'fred7', 'fred8', 'fred9', 'fred10'],
    },
    {
        projectId: '3',
        type: ['9'],
        customer: 'Round Top',
        location: 'Munich',
        year: 2022,
        description: 'Inspired by an experience I had at the age of fourteen, I decided to create this piece. In school, we had to write an essay about whether it would be a good idea to force girls to wear hideous and oversized tees provided by the school. To ensure that girls considered dressed “too provocatively” would no longer make male teachers and students uncomfortable. This work is my answer, made out of wire and deadstock nylon fabric. We should all be able to wear whatever we feel comfortable in, no more and no less, and this object was designed and crafted to communicate this message. Worldwide, women still get condemned for showing either too little or too much.',
        pictures: ['roundtop1', 'roundtop2'],
    },
    {
        projectId: '4',
        type: ['8', '2'],
        customer: '000 Undefined',
        location: 'Hồ Chí Minh City',
        year: 2022,
        collaborators: [
            {
                role: 'Designed together with',
                name: 'Minh Thao Dao',
            },
            {
                role: 'Project Management',
                name: 'Tin Nguyen',
            },
        ],
        description: `'UNDEFINED IS A FASHION HOUSE WITH A MULTIDISCIPLINARY APPROACH IN FEMININE STYLE. INSPIRED BY MODERN WOMEN WHO PLACE EMPHASIS ON THEIR GROWTH ON THE JOURNEY RATHER THAN THE DESTINATION, WE BELIEVE IN THE JOY OF SELF-DISCOVERY. TIME IS TOO SHORT TO BE LIMITED BY TRADITIONAL STEREOTYPES AND LABELS. YOU ARE FREE TO DEFINE YOURSELF.'`,
        pictures: ['undefined1', 'undefined2'],
    },
    {
        projectId: '5',
        type: ['1', '4', '6'],
        customer: 'COLLECTION MALOU',
        location: 'Heidelberg',
        year: 2020,
        description: 'Born in NYC, raised in Barcelona and Wiesbaden, and currently living in Heidelberg, Mara Louisa brought her own vintage fashion label to life – COLLECTION MALOU. A creative soul from an early age, her impressive and deep understanding of fashion has only been enhanced over time by her experiences in the industry. Collectively, we established the name "Collection Malou"; an obvious yet alluring name choice for a brand that is a collection of high-quality vintage clothes. The custom-typed wordmark is kept feminine and contemporary, illustrated by rectangular letter sizes and clear shapes. Posters, business cards as well as posters for purchasers were provided.',
        pictures: ['malou1', 'malou2', 'malou3', 'malou4'],
    },
    {
        projectId: '6',
        type: ['5'],
        customer: 'Marc O\'Polo',
        location: 'Stephanskirchen',
        year: 2022,
        collaborators: [
            {
                role: 'Photos',
                name: 'Marc O\'Polo in-house production'
            },
        ],
        description: 'Proposed version for the MOP Denim Guide newsletter communication.',
        pictures: ['mop1'],
    },
    {
        projectId: '7',
        type: ['4', '5'],
        customer: 'Blaze',
        location: 'Munich',
        year: 2020,
        pictures: ['blaze1', 'blaze2'],
    },
    {
        projectId: '8',
        type: ['1', '6'],
        customer: 'PPL JPG',
        location: 'Berlin, Hồ Chí Minh City',
        year: 2021,
        collaborators: [
            {
                role: 'Collaborator',
                name: 'Minh Thao Dao'
            },
        ],
        pictures: ['ppl1', 'ppl2'],
    },

    {
        projectId: '9',
        type: ['5'],
        customer: 'Marc O\'Polo',
        location: 'Stephanskirchen',
        year: 2022,
        description: 'Animation and visual concept developed to promote the “Friendship Day” for Marc O’Polo. The merging letter spacing visualizes cohesion.',
        pictures: ['friendshipday1'],
    },   

    {
        projectId: '10',
        type: ['3'],
        customer: 'DHABI STUDIO',
        location: 'Berlin',
        year: 2020,
        collaborators: [
            {
                role: 'Web Design',
                name: 'Daily Dialogue'
            },
        ],
        description: '2020 DHABI STUDIO approched me to bring their web presence (Design by Daily Dialogue) to life. Used languages (Dev including Content Management System): PHP; JavaScript; CSS; HTML www.dhabi.studio',
        pictures: ['dhabi1', 'dhabi2'],
    },

    {
        projectId: '11',
        type: ['2'],
        customer: 'Janis Josepha Strobl',
        location: 'Munich',
        year: 2021,
        collaborators: [
            {
                role: 'Artworks & Photography',
                name: 'Janis Josepha Strobl'
            },
        ],
        description: 'The works of Munich-based artist Janis Josepha Strobl are elegantly framed by the design. Small details like blur effects during interaction and radical cleanliness inspired by analog art books make the site a playful experience and promote the concept of the artist\'s work.',
        pictures: ['janis1', 'janis2', 'janis3', 'janis4', 'janis5', 'janis6', 'janis7'],
    },

    {
        projectId: '12',
        type: ['2'],
        customer: 'Visual Manipulator',
        location: 'Berlin',
        year: 2020,
        collaborators: [
            {
                role: 'Collaborator/ Showcased Work',
                name: 'Minh Thao Dao'
            },
        ],
        description: 'Minh Thao Dao aka. Visual Manipulator is a thriving designer from Berlin. Since her work is unique, playful and visionary, we incorporated this sentiment into the design and concept of her website as well. Therefore, we have focused on showcasing her work in a way that the user’s journey through the website feels playful yet informative at the same time.',
        pictures: ['visual1', 'visual2'],
    },
    {
        projectId: '13',
        type: ['9'],
        customer: 'BAC',
        location: 'Munich',
        year: 2022,
        collaborators: [
            {
                role: 'Model',
                name: 'Amelia'
            },
        ],
        pictures: ['bac1', 'bac2', 'bac3'],
        description: ['Bags made out of deadstock Construction Foil that was about to get thrown away. Video shot at the Algarve in Portugal.']
    },


    {
        projectId: '14',
        type: ['4'],
        location: 'Munich',
        year: 2019,
        pictures: ['tiger1', 'tiger2'],
    },

    {
        projectId: '15',
        type: ['4'],
        customer: 'Collection of free works',
        location: 'Munich',
        year: 2020,
        pictures: ['type1', 'type2', 'type3'],
    },


    {
        projectId: '16',
        type: ['4'],
        customer: 'The Renewery',
        location: 'Munich',
        year: 2020,
        collaborators: [
            {
                role: 'Images',
                name: 'The Renewery'
            },
        ],
        pictures: ['renewery1', 'renewery2', 'renewery3'],
    },

    {
        projectId: '17',
        type: ['6'],
        customer: 'Keine Zeit. Busy!',
        location: 'Munich',
        year: 2017,
        pictures: ['busy1', 'busy2', 'busy3', 'busy4', 'busy5', 'busy6'],
    },    

];

export const FILTERS = [
    {
        filterId: 1,
        name: 'Identity'
    },
    {
        filterId: 2,
        name: 'Web Design'
    },
    {
        filterId: 3,
        name: 'Web Development'
    },
    {
        filterId: 4,
        name: 'Type Design'
    },
    {
        filterId: 5,
        name: 'Animation and Graphic Concept'
    },
    {
        filterId: 6,
        name: 'Print'
    },
    {
        filterId: 7,
        name: 'Fotography'
    },
    {
        filterId: 8,
        name: 'Art Direction'
    },
    {
        filterId: 9,
        name: 'Object'
    },
    {
        filterId: 10,
        name: 'Packaging'
    },
];


export const CLIENT_LIST = [
    {
        name: 'GALVAN',
        location: 'London',
    },
    {
        name: 'Marco O\'Polo',
        location: 'Stephanskirchen',
    },
    {
        name: 'Splay',
        location: 'New York City',
    },
    {
        name: '000 UNDEFINED',
        location: 'Hồ Chí Minh City',
    },
    {
        name: 'Soulfood Society',
        location: 'Essen',
    },
    {
        name: 'DHABI STUDIO',
        location: 'Berlin',
    },
    {
        name: 'Visual Manipulator',
        location: 'Berlin',
    },
    {
        name: 'COLLECTION MALOU',
        location: 'Heidelberg',
    },
    {
        name: 'The Renewery',
        location: 'Munich',
    },
];