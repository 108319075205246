import makeStyles from "@mui/styles/makeStyles";
import { grey100 } from "../../other/helper/MuiStyles";

export const useStyles = makeStyles(() => ({   
    customer: {
        width: props => props.availableWidthCustomer,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    }, 
    project_box: {
        textDecoration: 'none',
    },
    tile_container: {
        cursor: 'pointer',
        position: 'relative',
        height: '200px',
        border: '6px solid #FFFFFF',
        filter: 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.09))',
        borderRadius: '3px',
    },
    tile_overlay: {
        cursor: 'pointer',
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        height: '100%',
        width: '100%',
        opacity: '0',
        transition: '.1s ease',
        backgroundColor: grey100,
        '&:hover': {
            opacity: '1',
        },
    },
    tile_view_picture: {
        cursor: 'ns-resize',
        opacity: '1',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '200px',
        width: 'auto',
    },
    year: {
        width: '30px',
    }
}));