import React, { useRef, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { useStyles } from './ProjectDetails.styles';
import { FILTERS, PROJECTS } from '../../../data/data';
import { black, grey100, lineHeight } from '../../../components/other/helper/MuiStyles';
import { IMAGE_MAP } from '../../../components/other/helper/ImgMapper';
import MoreProjectsBox from '../../../components/MoreProjectsBox/desktop/MoreProjectsBox';
import { useScroll } from 'react-use';

export default function ProjectDetails() {

    let { id } = useParams();
    const projectDetail = PROJECTS.find(object => {
        return object.projectId === id;
    });

    const projectPreviewHigher = PROJECTS.filter(object => object.projectId > projectDetail.projectId);
    const projectPreviewLower = PROJECTS.filter(object => object.projectId < projectDetail.projectId);

    const projectPreview = projectPreviewHigher.concat(projectPreviewLower);

    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [showMoreProjects, setShowMoreProjects] = useState(true);

    const handlePictureIndicationWidthSize = (amountPictures) => {
        switch (amountPictures) {
            case 1:
                return '0vw';
            case 2:
                return '3.5vw';
            case 3:
                return '3.5vw';
            case 4:
                return '3.5vw';
            case 5:
                return '3.5vw';
            case 6:
                return '2.5vw';
            case 7:
                return '2.5vw';
            case 10:
                return '1.5vw';
            default:
                return '0vw';
        }
    };

    const handleViewWidthConversion = (amountPictures) => {
        switch (amountPictures) {
            case 1:
                return 0;
            case 2:
                return 0.035;
            case 3:
                return 0.035;
            case 4:
                return 0.035;
            case 5:
                return 0.035;
            case 6:
                return 0.025;
            case 7:
                return 0.025;
            case 10:
                return 0.015;
            default:
                return 0;
        }
    };

    const gridRef = useRef(null);
    const scrollRef = useRef(null);
    const scrollHeight = useRef(null);
    const picturePositionIndicator = useRef(null);
    const picturePositionIndicator2 = useRef(null);
    const { y } = useScroll(scrollRef);
    const number2 = (gridRef.current?.clientWidth * handleViewWidthConversion(projectDetail.pictures?.length) * 0.78) * projectDetail.pictures?.length;

    const styleProps = {
        picture_indication_width: handlePictureIndicationWidthSize(projectDetail.pictures?.length),
        picture_indication_height: `calc(${handlePictureIndicationWidthSize(projectDetail.pictures?.length)} * 0.78)`,
        arrow_icon_direction: showMoreProjects ? 'rotate(90deg)' : 'rotate(-90deg)',
        picture_position_indication_width: handlePictureIndicationWidthSize(projectDetail.pictures?.length),
        picture_position_indication_heightFull: number2,

    }

    const classes = useStyles(styleProps);

    return (
        <Grid ref={gridRef} item xs={12} display="grid">
            <Box display="flex">
                <Box ref={scrollRef} pl={'20px'} pr={'12px'} display="flex" className={classes.project_picture_box}>
                    <Box ref={scrollHeight} display="flex" flexDirection="column" flexGrow={1} >
                        {projectDetail.pictures?.map((option, index) => {
                            return (
                                <div key={index}>
                                    {IMAGE_MAP.DESKTOP_DETAIL[option]?.includes("video") ?
                                        <div>
                                            <div className={classes.project_video_wrapper}>
                                                <iframe src={`https://player.vimeo.com/${IMAGE_MAP.DESKTOP_DETAIL[option]}?quality=1080p&background=1&dnt=1`}
                                                    className={classes.project_video}
                                                    id={index}
                                                    frameBorder="0"
                                                    title="movie"
                                                // allow="autoplay; fullscreen; picture-in-picture"
                                                >
                                                </iframe>
                                            </div>
                                            <script src="https://player.vimeo.com/api/player.js"></script>
                                        </div>
                                        :
                                        <Box id={index}
                                            // onMouseEnter={() => { setSelectedImageIndex(index) }} 
                                            // onWheel={() => {selectedImageIndex !== index ? setSelectedImageIndex(index) : (console.log("wheel")) }} 
                                            display="flex" flexDirection="column" justifyContent="center" className={classes.project_picture} style={{ backgroundImage: `url(${IMAGE_MAP.DESKTOP_DETAIL[option]})` }}>

                                        </Box>
                                    }
                                </div>
                            )
                        })}
                    </Box>
                </Box>

                <Box display="flex" flexDirection="column" justifyContent="space-between" flexGrow={1} className={classes.paper}>
                    <Box display="flex" flexDirection="column">
                        {/* DESCRIPTION */}
                        <Typography variant="body1" className={classes.description_text}>
                            {projectDetail.description}
                        </Typography>
                        {/* COLLABORATORS */}
                        <Box mt={projectDetail.description ? lineHeight : ''}>
                            {projectDetail.collaborators?.map((option, index) => {
                                return (
                                    <Typography key={index} variant="body1">
                                        {option.role}: {option.name}
                                    </Typography>
                                )
                            })}
                        </Box>

                        {/* CUSTOMER */}
                        <Box mt={(!projectDetail.description && !projectDetail.collaborators) ? '' : '18px'}>
                            <Typography variant="body1">
                                {projectDetail.customer}
                            </Typography>
                        </Box>
                        {/* TYPES */}
                        {projectDetail.type?.map((option, index) => {
                            return (
                                <Typography key={index} variant="body1">
                                    {FILTERS[option - 1].name},
                                </Typography>
                            )
                        })}
                        {/* YEAR */}
                        <Typography variant="h1">
                            {projectDetail.year}
                        </Typography>
                    </Box>


                    {!number2 ?
                        < Box display="flex" flexDirection="column">
                            {projectDetail.pictures?.map((option, index) => {
                                return (
                                    <a href={`#${index}`} onClick={() => setSelectedImageIndex(index)} key={index} className={classes.picture_box} 
                                    style={{ borderBottom: index === projectDetail.pictures?.length - 1 ? `0.25px solid ${black}` : '', backgroundColor: selectedImageIndex === index ? '#FFF' : grey100 }}>

                                    </a>
                                )
                            })}
                        </Box>

                    :

                    <Box ref={picturePositionIndicator} display="flex" className={classes.picture_position_indicator_container}>
                        <Box display="flex" flexDirection="column" className={classes.picture_position_indicator_box}>
                            {projectDetail.pictures?.map((option, index) => {
                                return (
                                    <a href={`#${index}`} onClick={() => setSelectedImageIndex(index)} key={index} className={classes.picture_box_background}
                                        style={{ borderBottom: index === projectDetail.pictures?.length - 1 ? `0.25px solid #FFF` : '' }}
                                    >

                                    </a>
                                )
                            })}
                        </Box>
                        {/* MOVING PART */}
                        <Box display="flex" className={classes.picture_position_indicator_grey} style={{ marginTop: `calc((${y}px / (${scrollHeight.current?.scrollHeight} - ${scrollHeight.current?.clientHeight})) * (${picturePositionIndicator.current?.clientHeight} - ${picturePositionIndicator.current?.clientHeight / projectDetail.pictures?.length})` }}>
                        </Box>
                        {/* GRID OVERLAY */}
                        <Box ref={picturePositionIndicator2} display="flex" flexDirection="column">
                            {projectDetail.pictures?.map((option, index) => {
                                return (
                                    <a key={index} href={`#${index}`} onClick={() => setSelectedImageIndex(index)} className={classes.picture_box_grid}
                                        style={{ borderBottom: index === projectDetail.pictures?.length - 1 ? `0.25px solid ${black}` : '', marginTop: `calc(${handlePictureIndicationWidthSize(projectDetail.pictures?.length)} * 0.78 * ${index})`}}
                                    >

                                    </a>
                                )
                            })}
                        </Box>
                    </Box>
                    }
                </Box>

                {/* MORE PROJECTS */}
                <Box pr={3} display="flex" alignItems="flex-end" justifyContent="flex-end" className={classes.paper}>

                    <Box display="flex" flexDirection="column">
                        <Box display="flex" alignItems="flex-end" px="3px" className={classes.more_projects_box} onClick={() => setShowMoreProjects(!showMoreProjects)}>
                            <Typography variant="body1" color="blue" fontSize="10px">
                                {showMoreProjects ? "less" : "more"}
                            </Typography>
                            <Typography variant="body1" px="3px" color="blue" fontSize="10px" className={classes.arrow_icon_direction}>
                                &gt;
                            </Typography>
                        </Box>
                        {showMoreProjects &&
                            <Box display="flex" flexDirection="column">
                                {projectPreview.map((option, index) => {
                                    return (
                                        <Box key={index} display="flex">
                                            {index < 10 &&
                                                <Link to={`/project/${option.projectId}`} key={index}>
                                                    <MoreProjectsBox key={index} project={option} />
                                                </Link>
                                            }
                                        </Box>
                                    )
                                })}
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        </Grid >

    )
}