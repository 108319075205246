import makeStyles from "@mui/styles/makeStyles";
import { grey50, innerHeightBig } from "../../other/helper/MuiStyles";

export const useStyles = makeStyles(() => ({
    full_view_picture: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        width: '100vw',
    },
    full_view_wrapper: {
        zIndex: '1',
        width: 'auto',
        height: innerHeightBig,
        cursor: 'none',
        opacity: '95%',
        transition: '.1s ease',
        '&:hover': {
            borderBottom: '1px red',
            opacity: '99%',
        },
    },
    // project_video: {
    //     PointerEvents: 'auto !important',
    //     position: 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     width: '100vw',
    //     height: '100vh',
    //     transform: 'translate(-50%, -50%)',
    // },
    // project_video_wrapper: {
    //     PointerEvents: 'auto !important',
    //     position: 'relative',
    //     overflow: 'hidden',
    //     height: innerHeightBig,
    //     width: '100vw',
    // },

    project_video: {
        PointerEvents: 'auto !important',
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '100vw',
        // paddingLeft: '50px',
        // paddingRight: '50px',
        height: innerHeightBig,
        transform: 'translate(-50%, -50%)',
    },
    project_video_wrapper: {
        PointerEvents: 'auto !important',
        position: 'relative',
        overflow: 'hidden',
        height: innerHeightBig,
        width: '100vw',
        backgroundColor: grey50,
    },

    video_overlay: {
        position: 'absolute',
        zIndex: '10000',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        height: '100%',
        width: '100%',
        opacity: '1%',
        transition: '.1s ease',
        backgroundColor: grey50,
    },

}));