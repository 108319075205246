import React, { useState } from 'react'

const GeneralContext = React.createContext(null);

const GeneralProvider = ({ children }) => {
    const [isFilterSet, setIsFilterSet] = useState(false);
    const [isTileViewSelected, setIsTileViewSelected] = useState(false);
    const [cookiesAccepted, setCookiesAccepted] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState([]);

    return (
        <GeneralContext.Provider value={{
            isFilterSet, setIsFilterSet,
            isTileViewSelected, setIsTileViewSelected,
            cookiesAccepted, setCookiesAccepted,
            selectedFilters, setSelectedFilters,
        }}
        >
            {children}
        </GeneralContext.Provider>
    )
};

export {GeneralContext, GeneralProvider}