import React, { useEffect, useRef } from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';
import { useStyles } from './About.styles';
import { CLIENT_LIST } from '../../../data/data';
import { IMAGE_MAP } from '../../../components/other/helper/ImgMapper';

export default function About() {

    const classes = useStyles();
    const ref = useRef(null);
  
    useEffect(() => {
        const img_about = ref.current;
        const onMouseMove = (e) => {
                img_about.style.transition = 'left 1s ease-out, right 1.5s ease-out, top 1.3s ease-out, opacity 0.5s';
                img_about.style.opacity = '1';
                img_about.style.left = (e.pageX - 80) + 'px';
                img_about.style.top = (e.pageY - 20) + 'px';
        }
        window.addEventListener('mousemove', onMouseMove);

      }, []);

    return (
        <Grid container>
            <Grid item xs={5}>
                <Box px={'20px'} display="flex" flexDirection="column" justifyContent="center" className={classes.paper}>
                    <Typography variant="body1" className={classes.paragraph}>
                    Working multidisciplinary, mainly in graphic design and art direction, I focus on creating classic yet distinctly contemporary and unconventional outcomes.
                    </Typography>
                    <Typography variant="h2" style={{ paddingTop: '74px' }}>
                    Selected Clients
                    </Typography>
                    {CLIENT_LIST.map((option, index) => {
                        return (
                            <Typography key={index} variant="body1">
                                {option.name} - {option.location}
                            </Typography>
                        )
                    })}
                    <Typography variant="body1" style={{ paddingTop: '50px' }}>
                        Contact
                    </Typography>
                    <Typography variant="body1">
                        Carla Maria Pecho, <a href="mailto:studio@carlamariapecho.com"><Typography variant="caption">studio@carlamariapecho.com</Typography></a>,
                    </Typography>
                    <Typography variant="body1">
                        Instagram: 
                            <Link href='https://www.instagram.com/carlamariapecho/' target='_blank' rel='noopener noreferrer'>
                            <Typography variant="caption">carlamariapecho</Typography></Link> ; 
                            <Link href='https://www.instagram.com/3118121__/' target='_blank' rel='noopener noreferrer'>
                            <Typography variant="caption">3118121__</Typography></Link>
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={7}>
                <Box px={3} display="flex" flexDirection="column" justifyContent="center" className={classes.paper}>
                    <img ref={ref} className={classes.about_img} src={IMAGE_MAP.ABOUT.about} alt={"Carla Pecho"} style={{ opacity: '0' }} />
                </Box>
            </Grid>
        </Grid>

    )
}