import makeStyles from "@mui/styles/makeStyles";


export const useStyles = makeStyles(() => ({    
    paper: {
        minHeight: props => props.contentHeight,
        cursor: 'ns-resize',
        // overflow: 'auto',
        // msOverflowStyle: 'none', /* IE and Edge */
        // scrollbarWidth: 'none', /* Firefox */
        // '&::-webkit-scrollbar': {
        //     display: 'none',
        // }
    },

    full_view_wrapper:{
        // scrollBehavior: 'smooth',
        maxWidth: '100vw',
        overflow: 'auto',
        msOverflowStyle: 'none', /* IE and Edge */
        scrollbarWidth: 'none', /* Firefox */
        '&::-webkit-scrollbar': {
            display: 'none',
        }
    },

    tile_view_wrapper:{
        cursor: 'ns-resize',
        maxWidth: '100vw',
        overflow: 'auto',
        msOverflowStyle: 'none', /* IE and Edge */
        scrollbarWidth: 'none', /* Firefox */
        '&::-webkit-scrollbar': {
            display: 'none',
        }
    },

    filterContainer: {
        maxWidth: 'calc(100vw - 33px - 42px)',
        overflow: 'auto',
        whiteSpace: 'nowrap',
        msOverflowStyle: 'none', /* IE and Edge */
        scrollbarWidth: 'none', /* Firefox */
        '&::-webkit-scrollbar': {
            display: 'none',
        }
    },
}));