import React, { useContext, useRef, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useStyles } from './TileViewPageMobile.styles';
import Icon from '../../../assets/icons/Icon';
import { Link } from 'react-router-dom';
import TileProjectBoxMobile from '../../../components/TileProjectBox/mobile/TileProjectBoxMobile';
import { GeneralContext } from '../../../components/other/context/GeneralContext';
import { grey100, innerHeightMobile, innerHeightMobileFilterSet, } from '../../../components/other/helper/MuiStyles';
import { FILTERS, PROJECTS } from '../../../data/data';

const concatedArray = [];
for (let i = 0; i < PROJECTS.length; i++) {
    concatedArray.push(...PROJECTS[i].type);
};

const filters = [...new Set(concatedArray.map(object => object))];

const getFilteredProjects = (filters) => {
    return filters.length === 0
        ? PROJECTS
        : PROJECTS.filter(project => project.type.includes(filters[0]));
};

export default function TileViewPageMobile() {

    const { isFilterSet, isTileViewSelected, selectedFilters, setSelectedFilters } = useContext(GeneralContext);
    const [selectedProjects, setSelectedProjects] = useState(() => getFilteredProjects(selectedFilters));
    const [filterKey, setFilterKey] = useState(false);

    const styleProps = {
        contentHeight: isTileViewSelected ? innerHeightMobile : innerHeightMobileFilterSet,
    }

    const classes = useStyles(styleProps);

    // HORIZONTAL SCROLL
    const ref = useRef(null);

    const handleFilterChange = (option) => () => {
        if (option) {
            const array = selectedFilters.includes(option) ? [] : [option];
            setSelectedFilters(array);
            setSelectedProjects(getFilteredProjects(array));
            setFilterKey(prevKey => !prevKey);
        }
    };

    let vh = window.innerHeight;
    let vhviewport = vh - vh * 0.05 - vh * 0.02 - 39;

    window.onresize = function () {
        vh = window.innerHeight;
        vhviewport = vh - vh * 0.05 - vh * 0.02 - 39;
    }
    window.onresize();

    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" height={vhviewport} className={classes.paper} >
            {isFilterSet &&
                <Box px={'21px'} pb={'10px'} display="flex" alignItems="center" className={classes.filter_wrapper}>
                    <Typography>filter:</Typography>
                    <Box key={filterKey} className={classes.filter_container}>
                        {filters?.slice().sort((a, b) => {
                            if (selectedFilters.includes(a)) return -1;
                            if (selectedFilters.includes(b)) return 1;
                            return 0;
                        }).map((option, index) => (
                            <Button
                                disableRipple
                                key={index}
                                name={option}
                                variant="contained"
                                style={{ marginLeft: '14px', backgroundColor: selectedFilters.includes(option) ? grey100 : '' }}
                                onClick={handleFilterChange(option)}
                                position="relative"
                            >
                                <div style={{ display: 'flex', paddingRight: '8px', pointerEvents: 'none'  }}>
                                    <Icon iconName={selectedFilters.includes(option) ? 'MinusIcon' : 'PlusIcon'} />
                                </div>
                                {FILTERS[option - 1].name}
                            </Button>
                        ))
                        }
                    </Box>
                </Box>
            }
            <Box ref={ref} display="flex" alignItems="flex-start" flexGrow={1} flexWrap="wrap" className={classes.tile_view_wrapper} >
                {selectedProjects?.map((option) => {
                    return (
                        <Link to={`/project/${option.projectId}`} key={option.projectId}>
                            <TileProjectBoxMobile key={option.projectId} project={option} />
                        </Link>
                    )
                })}
            </Box>
        </Box>
    )
}