import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './FullScreenBoxMobile.styles';
import { IMAGE_MAP } from '../../other/helper/ImgMapper';
import { GeneralContext } from '../../other/context/GeneralContext';

export default function FullScreenBoxMobile({ project }) {

    const ref = useRef(null);
    const ref2 = useRef(null);
    const { isFilterSet } = useContext(GeneralContext);
    const [isShown, setIsShown] = useState(false);
    let value = 'none';

    isShown ? value = 'block' : value = 'none';

    useEffect(() => {
        const cursor_scrll = ref.current;
        const wrapper = ref2.current;

        const onMouseMove = (e) => {
            cursor_scrll.style.transition = 'linear';
            cursor_scrll.style.left = (e.pageX) + 'px';
            cursor_scrll.style.top = (e.pageY) + 'px';
        }

        wrapper.addEventListener('mousemove', onMouseMove)

    }, []);

    let vh = window.innerHeight;
    let vhviewport = vh - vh * 0.05 - vh * 0.02 - 39;
    let vhfilterviewport = vh - vh * 0.05 - vh * 0.02 - 39 - 28;

    window.onresize = function () {
        vh = window.innerHeight;
    }
    window.onresize();

    let styleProps = {
        innerHeightMobileTest: isFilterSet ? vhfilterviewport + 'px' : vhviewport + 'px',
    }

    const classes = useStyles(styleProps);

    return (
        <Box ref={ref2} display="flex" className={classes.full_view_wrapper} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)} >
            <Typography ref={ref} display={value} position="absolute">SCROLL</Typography>
            {IMAGE_MAP.MOBILE_FULL[project.pictures[0]]?.includes("video") ?
                <Box position="relative">
                    <div className={classes.video_overlay}></div>
                    <div>
                        <div className={classes.project_video_wrapper}>
                            <iframe src={`https://player.vimeo.com/${IMAGE_MAP.MOBILE_FULL[project.pictures[0]]}?quality=1080p&background=1&dnt=1&h=5de0de29f9&title=0&byline=0&portrait=0`}
                                className={classes.project_video}
                                frameBorder="0"
                                title="movie"
                                allow="autoplay; fullscreen; picture-in-picture"
                                >
                            </iframe>
                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                    </div>
                </Box>
                :
                <Box className={classes.full_view_picture} style={{ backgroundImage: `url(${IMAGE_MAP.MOBILE_FULL[project.pictures[0]]})` }} >
                </Box>

            }
        </Box>
    )
}