// ABOUT
import about from '../../../assets/images/about/carlamariapecho-about.png';

// PROJECTS

// Soulfood Society
import soulfood1_desktop_detail1 from '../../../assets/images/soulfood/sfs_desktop_detail1.jpg';
import soulfood1_desktop_detail2 from '../../../assets/images/soulfood/sfs_desktop_detail2.jpg';
import soulfood1_desktop_detail3 from '../../../assets/images/soulfood/sfs_desktop_detail3.jpg';
import soulfood1_desktop_detail4 from '../../../assets/images/soulfood/sfs_desktop_detail4.jpg';
import soulfood1_desktop_detail5 from '../../../assets/images/soulfood/sfs_desktop_detail5.jpg';
import soulfood1_desktop_full from '../../../assets/images/soulfood/sfs_desktop_full.jpg';
import soulfood1_desktop_more from '../../../assets/images/soulfood/sfs_desktop_more.jpg';
import soulfood1_desktop_tile from '../../../assets/images/soulfood/sfs_desktop_tile.jpg';
import soulfood1_mobile_detail1 from '../../../assets/images/soulfood/sfs_mobile_detail1.jpg';
import soulfood1_mobile_detail2 from '../../../assets/images/soulfood/sfs_mobile_detail2.jpg';
import soulfood1_mobile_detail3 from '../../../assets/images/soulfood/sfs_mobile_detail3.jpg';
import soulfood1_mobile_detail4 from '../../../assets/images/soulfood/sfs_mobile_detail4.jpg';
import soulfood1_mobile_detail5 from '../../../assets/images/soulfood/sfs_mobile_detail5.jpg';
import soulfood1_mobile_full from '../../../assets/images/soulfood/sfs_mobile_full_tile.jpg';

// Round Top
import roundtop_desktop_detail1 from '../../../assets/images/round-top/round-top-desktop-detail1.gif';
import roundtop_desktop_detail2 from '../../../assets/images/round-top/round-top-desktop-detail2.jpg';
import roundtop_desktop_full from '../../../assets/images/round-top/round-top-desktop-full.gif';
import roundtop_desktop_tile from '../../../assets/images/round-top/round-top-desktop-tile.gif';
import roundtop_more from '../../../assets/images/round-top/round-top-desktop-mobile-more.gif';
import roundtop_mobile_full_tile from '../../../assets/images/round-top/round-top-mobile-full-tile.gif';
import roundtop_mobile_detail1 from '../../../assets/images/round-top/round-top-mobile-detail1.gif';
import roundtop_mobile_detail2 from '../../../assets/images/round-top/round-top-mobile-detail2.jpg';

// Collection Malou
import malou_desktop_full from '../../../assets/images/malou/malou-desktop-full.svg';
import malou_more from '../../../assets/images/malou/malou-desktop-more.jpg';
import malou_desktop_tile from '../../../assets/images/malou/malou-desktop-tile.jpg';
import malou_mobile_detail1 from '../../../assets/images/malou/malou-mobile-detail1.jpg';
import malou_mobile_detail2 from '../../../assets/images/malou/malou-mobile-detail2.jpg';
import malou_mobile_detail3 from '../../../assets/images/malou/malou-mobile-detail3.jpg';
import malou_mobile_detail4 from '../../../assets/images/malou/malou-mobile-detail4.jpg';
import malou_mobile_full_tile from '../../../assets/images/malou/malou-mobile-full-tile.jpg';

// ppljpg
import ppl_desktop_full from '../../../assets/images/ppl/ppl-jpg-desktop-full.jpg';
import ppl_more from '../../../assets/images/ppl/ppl-jpg-desktop-more.jpg';
import ppl_desktop_tile from '../../../assets/images/ppl/ppl-jpg-desktop-tile.jpg';
import ppl_detail1 from '../../../assets/images/ppl/ppl-jpg-detail1.jpg';
import ppl_detail2 from '../../../assets/images/ppl/ppl-jpg-detail2.jpg';
import ppl_mobile_full_tile from '../../../assets/images/ppl/ppl-jpg-mobile-full-tile.jpg';

// Friendship Day
import friend_desktop_full from '../../../assets/images/friendshipday/friendshipday-desktop-full.jpg';
import friend_desktop_tile from '../../../assets/images/friendshipday/friendshipday-desktop-tile.jpg';
import friend_detail from '../../../assets/images/friendshipday/friendshipday-mobile-full.gif';
import friend_mobile_full from '../../../assets/images/friendshipday/friendshipday-mobile-full.jpg';

// 000
import undefined_desktop_full from '../../../assets/images/000/000-desktop-full.jpg';
import undefined_more from '../../../assets/images/000/000-more.jpg';
import undefined_desktop_tile from '../../../assets/images/000/000-desktop-tile.jpg';
// import undefined_desktop_detail1 from '../../../assets/images/000/000-desktop-detail1.jpg';
import undefined_mobile_full from '../../../assets/images/000/000-mobile-full-tile.jpg';
// import undefined_mobile_detail1 from '../../../assets/images/000/000-mobile-detail1.jpg';

// Visual Manipulator
// import visual_desktop_full from '../../../assets/images/visual/visual-desktop-full.jpg'; => REPLACED BY VIDEO
import visual_more from '../../../assets/images/visual/visual-more.jpg';
import visual_desktop_tile from '../../../assets/images/visual/visual-desktop-tile.jpg';
// import visual_desktop_detail1 from '../../../assets/images/visual/visual-desktop-detail1.jpg'; => REPLACED BY VIDEO
import visual_desktop_detail2 from '../../../assets/images/visual/visual-desktop-detail2.jpg';
import visual_mobile_full_tile from '../../../assets/images/visual/visual-mobile-full-tile.jpg';
// import visual_mobile_detail1 from '../../../assets/images/visual/visual-mobile-detail1.jpg'; => REPLACED BY VIDEO
import visual_mobile_detail2 from '../../../assets/images/visual/visual-mobile-detail2.jpg';

// Blaze
import blaze_desktop_full from '../../../assets/images/blaze/blaze-desktop-full.gif';
import blaze_more from '../../../assets/images/blaze/blaze-more.jpg';
import blaze_desktop_tile from '../../../assets/images/blaze/blaze-desktop-tile.jpg';
import blaze_desktop_detail1 from '../../../assets/images/blaze/blaze-desktop-detail1.gif';
import blaze_desktop_detail2 from '../../../assets/images/blaze/blaze-desktop-detail2.jpg';
import blaze_mobile_full from '../../../assets/images/blaze/blaze-mobile-full.gif';
import blaze_mobile_tile from '../../../assets/images/blaze/blaze-mobile-tile.jpg';
import blaze_mobile_detail1 from '../../../assets/images/blaze/blaze-mobile-detail1.gif';
import blaze_mobile_detail2 from '../../../assets/images/blaze/blaze-mobile-detail2.jpg';

// Tiger
import tiger_desktop_full from '../../../assets/images/tiger/tiger-desktop-full.jpg';
import tiger_more from '../../../assets/images/tiger/tiger-more.jpg';
import tiger_desktop_tile from '../../../assets/images/tiger/tiger-desktop-detail1.jpg';
import tiger_detail1 from '../../../assets/images/tiger/tiger-desktop-detail1.jpg';
import tiger_detail2 from '../../../assets/images/tiger/tiger-desktop-detail2.jpg';
import tiger_mobile from '../../../assets/images/tiger/tiger-mobile-full-tile.jpg';

// Fred Studios
import fred_desktop_full from '../../../assets/images/fred/fred-desktop-full.jpg';
import fred_more from '../../../assets/images/fred/fred-more.jpg';
import fred_desktop_tile from '../../../assets/images/fred/fred-desktop-tile.jpg';
import fred_desktop_detail1 from '../../../assets/images/fred/fred-desktop-detail1.png';
import fred_desktop_detail2 from '../../../assets/images/fred/fred-desktop-detail2.png';
import fred_desktop_detail3 from '../../../assets/images/fred/fred-desktop-detail3.svg';
// import fred_desktop_detail4 from '../../../assets/images/fred/fred-desktop-detail4.gif';
// import fred_desktop_detail5 from '../../../assets/images/fred/fred-desktop-detail5.gif';
import fred_desktop_detail6 from '../../../assets/images/fred/fred-desktop-detail6.jpg';
import fred_mobile_detail6 from '../../../assets/images/fred/fred-mobile-detail6.jpg';
import fred_desktop_detail7 from '../../../assets/images/fred/fred-desktop-detail7.jpg';
import fred_desktop_detail8 from '../../../assets/images/fred/fred-desktop-detail8.gif';
import fred_mobile_detail8 from '../../../assets/images/fred/fred-mobile-detail8.gif';
import fred_desktop_detail9 from '../../../assets/images/fred/fred-desktop-detail10.jpg';
import fred_desktop_detail10 from '../../../assets/images/fred/fred-desktop-detail12.svg';
import fred_mobile_full_tile from '../../../assets/images/fred/fred-mobile-full-tile.jpg';

// Janis Strobl
import janis_desktop_full from '../../../assets/images/janis/janis-desktop-full.jpg';
import janis_more from '../../../assets/images/janis/janis-more.jpg';
import janis_desktop_tile from '../../../assets/images/janis/janis-desktop-tile.gif';
import janis_desktop_detail1 from '../../../assets/images/janis/janis-desktop-detail1.gif';
import janis_desktop_detail2 from '../../../assets/images/janis/janis-desktop-detail2.jpg';
import janis_desktop_detail3 from '../../../assets/images/janis/janis-desktop-detail3.jpg';
import janis_desktop_detail4 from '../../../assets/images/janis/janis-desktop-detail4.jpg';
import janis_desktop_detail5 from '../../../assets/images/janis/janis-desktop-detail5.jpg';
import janis_desktop_detail6 from '../../../assets/images/janis/janis-desktop-detail6.jpg';
import janis_desktop_detail7 from '../../../assets/images/janis/janis-desktop-detail7.jpg';
import janis_mobile_full_tile from '../../../assets/images/janis/janis-mobile-full-tile.jpg';
import janis_mobile_detail1 from '../../../assets/images/janis/janis-mobile-detail1.jpg';
import janis_mobile_detail2 from '../../../assets/images/janis/janis-mobile-detail2.jpg';
import janis_mobile_detail3 from '../../../assets/images/janis/janis-mobile-detail3.jpg';
import janis_mobile_detail4 from '../../../assets/images/janis/janis-mobile-detail4.jpg';
import janis_mobile_detail5 from '../../../assets/images/janis/janis-mobile-detail5.gif';
import janis_mobile_detail6 from '../../../assets/images/janis/janis-mobile-detail6.jpg';
import janis_mobile_detail7 from '../../../assets/images/janis/janis-mobile-detail7.jpg';

// type
import type_desktop_full from '../../../assets/images/type/type-desktop-full.jpg';
import type_more from '../../../assets/images/type/type-more.jpg';
import type_desktop_tile from '../../../assets/images/type/type-desktop-tile.jpg';
import type_desktop_detail1 from '../../../assets/images/type/type-desktop-detail1.jpg';
import type_desktop_detail2 from '../../../assets/images/type/type-desktop-detail2.jpg';
import type_desktop_detail3 from '../../../assets/images/type/type-desktop-detail3.jpg';
import type_mobile_full_tile from '../../../assets/images/type/type-mobile-full-tile.jpg';
import type_mobile_detail1 from '../../../assets/images/type/type-mobile-detail1.jpg';
import type_mobile_detail2 from '../../../assets/images/type/type-mobile-detail2.jpg';
import type_mobile_detail3 from '../../../assets/images/type/type-mobile-detail3.jpg';

// bac
import bac_desktop_full from '../../../assets/images/bac/bac-desktop-full.jpg';
import bac_more from '../../../assets/images/bac/bac-more.jpg';
import bac_desktop_tile from '../../../assets/images/bac/bac-desktop-tile.jpg';
// import bac_desktop_detail1 from '../../../assets/images/bac/bac-desktop-detail1.jpg';
import bac_desktop_detail2 from '../../../assets/images/bac/bac-desktop-detail2.jpg';
import bac_desktop_detail3 from '../../../assets/images/bac/bac-desktop-detail3.jpg';
import bac_mobile_full_tile from '../../../assets/images/bac/bac-mobile-full-tile.jpg';
// import bac_mobile_detail1 from '../../../assets/images/bac/bac-mobile-detail1.jpg';
import bac_mobile_detail2 from '../../../assets/images/bac/bac-mobile-detail2.jpg';
import bac_mobile_detail3 from '../../../assets/images/bac/bac-mobile-detail3.jpg';

// dhabi
import dhabi_desktop_full from '../../../assets/images/dhabi/dhabi-desktop-full.jpg';
import dhabi_more from '../../../assets/images/dhabi/dhabi-more.jpg';
import dhabi_desktop_tile from '../../../assets/images/dhabi/dhabi-desktop-tile.jpg';
import dhabi_mobile_full_tile from '../../../assets/images/dhabi/dhabi-mobile-full-tile.jpg';

// renewery
import renewery_desktop_full from '../../../assets/images/renewery/renewery-desktop-full.svg';
import renewery_more from '../../../assets/images/renewery/renewery-desktop-full.svg';
import renewery_desktop_tile from '../../../assets/images/renewery/renewery-desktop-tile.svg';
import renewery_desktop_detail1 from '../../../assets/images/renewery/renewery-desktop-full.svg';
import renewery_mobile_full_tile from '../../../assets/images/renewery/renewery-mobile-full-tile.svg';
import renewery_mobile_detail1 from '../../../assets/images/renewery/renewery-desktop-full.svg';

// busy
import busy_desktop_full from '../../../assets/images/busy/busy-desktop-full.png';
import busy_more from '../../../assets/images/busy/busy-desktop-full.png';
import busy_desktop_tile from '../../../assets/images/busy/busy-desktop-tile.jpg';
import busy_desktop_detail1 from '../../../assets/images/busy/busy-desktop-full.png';
import busy_desktop_detail2 from '../../../assets/images/busy/busy-desktop-detail1.jpg';
import busy_desktop_detail3 from '../../../assets/images/busy/busy-desktop-detail2.jpg';
import busy_desktop_detail4 from '../../../assets/images/busy/busy-desktop-detail3.jpg';
import busy_desktop_detail5 from '../../../assets/images/busy/busy-desktop-detail4.jpg';
import busy_mobile_full_tile from '../../../assets/images/busy/busy-desktop-full.png';
import busy_mobile_detail1 from '../../../assets/images/busy/busy-desktop-full.png';

// mop
import mop_mobile_full_tile from '../../../assets/images/mop/mop-mobile-full-tile.jpg';
import mop_more from '../../../assets/images/mop/mop-more.jpg';
import mop_desktop_tile from '../../../assets/images/mop/mop-desktop-tile.jpg';

export const IMAGE_MAP = {
    DESKTOP_FULL: {
        // Soulfood Society     
        soulfood1: soulfood1_desktop_full,
        // Round Top
        roundtop1: roundtop_desktop_full,
        // Collection Malou
        malou1: malou_desktop_full,
        // ppl jpg
        ppl1: ppl_desktop_full,
        // friendshipday
        friendshipday1: friend_desktop_full,
        // 000 undefined
        undefined1: undefined_desktop_full,
        // Fred Studios
        fred1: fred_desktop_full,
        // Visual Manipulator
        visual1: 'video/771685735',
        // Janis J Strobl
        janis1: janis_desktop_full,
        // Blaze
        blaze1: blaze_desktop_full,
        // Tiger
        tiger1: tiger_desktop_full,
        // Type
        type1: type_desktop_full,
        // bac
        bac1: bac_desktop_full,
        // dhabi
        dhabi1: dhabi_desktop_full,
        // renewery
        renewery1: renewery_desktop_full,
        // busy
        busy1: busy_desktop_full,
        // mop
        mop1: 'video/774761307',
    },

    DESKTOP_DETAIL: {
        // Soulfood Society
        soulfood1: soulfood1_desktop_detail1,
        soulfood2: soulfood1_desktop_detail2,
        soulfood3: soulfood1_desktop_detail3,
        soulfood4: soulfood1_desktop_detail4,
        soulfood5: soulfood1_desktop_detail5,
        // Round Top
        roundtop1: roundtop_desktop_detail1,
        roundtop2: roundtop_desktop_detail2,
        // Collection Malou
        malou1: malou_mobile_detail1,
        malou2: malou_mobile_detail2,
        malou3: malou_mobile_detail3,
        malou4: malou_mobile_detail4,
        // ppl jpg
        ppl1: ppl_detail1,
        ppl2: ppl_detail2,
        // friendshipday
        friendshipday1: friend_detail,
        // 000 undefined
        undefined1: 'video/772426828',
        undefined2: 'video/772535179',
        // Fred Studios
        fred1: fred_desktop_detail1,
        fred2: fred_desktop_detail2,
        fred3: fred_desktop_detail3,
        fred4: 'video/771989571',
        fred5: 'video/771986564',
        fred6: fred_desktop_detail6,
        fred7: fred_desktop_detail7,
        fred8: fred_desktop_detail8,
        fred9: fred_desktop_detail9,
        fred10: fred_desktop_detail10,
        // Visual Manipulator
        visual1: 'video/771685735',
        visual2: visual_desktop_detail2,
        // Janis J Strobl
        janis1: janis_desktop_detail1,
        janis2: janis_desktop_detail2,
        janis3: janis_desktop_detail3,
        janis4: janis_desktop_detail4,
        janis5: janis_desktop_detail5,
        janis6: janis_desktop_detail6,
        janis7: janis_desktop_detail7,
        // Blaze
        blaze1: blaze_desktop_detail1,
        blaze2: blaze_desktop_detail2,
        // Tiger
        tiger1: tiger_detail1,
        tiger2: tiger_detail2,
        // Type
        type1: type_desktop_detail1,
        type2: type_desktop_detail2,
        type3: type_desktop_detail3,
        // bac
        bac1: 'video/772066039',
        bac2: bac_desktop_detail2,
        bac3: bac_desktop_detail3,
        // dhabi
        dhabi1: 'video/773328055',
        dhabi2: 'video/773337390',
        // renewery
        renewery1: renewery_desktop_detail1,
        renewery2: 'video/773284773',
        renewery3: 'video/773284802',
        // busy
        busy1: busy_desktop_detail1,
        busy2: 'video/773736993',
        busy3: busy_desktop_detail2,
        busy4: busy_desktop_detail3,
        busy5: busy_desktop_detail4,
        busy6: busy_desktop_detail5,
        // mop
        mop1: 'video/774767766',
    },

    DESKTOP_TILE: {
        // Soulfood Society
        soulfood1: soulfood1_desktop_tile,
        // Round Top
        roundtop1: roundtop_desktop_tile,
        // Collection Malou
        malou1: malou_desktop_tile,
        // ppl jpg
        ppl1: ppl_desktop_tile,
        // friendshipday
        friendshipday1: friend_desktop_tile,
        // 000 undefined
        undefined1: undefined_desktop_tile,
        // Fred Studios
        fred1: fred_desktop_tile,
        // Visual Manipulator
        visual1: visual_desktop_tile,
        // Janis J Strobl
        janis1: janis_desktop_tile,
        // Blaze
        blaze1: blaze_desktop_tile,
        // Tiger
        tiger1: tiger_desktop_tile,
        // Type
        type1: type_desktop_tile,
        // bac
        bac1: bac_desktop_tile,
        // dhabi
        dhabi1: dhabi_desktop_tile,
        // renewery
        renewery1: renewery_desktop_tile,
        // busy
        busy1: busy_desktop_tile,
        // mop
        mop1: mop_desktop_tile,
    },

    DESKTOP_MORE: {
        // Soulfood Society
        soulfood1: soulfood1_desktop_more,
        // Round Top
        roundtop1: roundtop_more,
        // Collection Malou
        malou1: malou_more,
        // ppl jpg
        ppl1: ppl_more,
        // friendshipday
        friendshipday1: friend_detail,
        // 000 undefined
        undefined1: undefined_more,
        // Fred Studios
        fred1: fred_more,
        // Visual Manipulator
        visual1: visual_more,
        // Janis J Strobl
        janis1: janis_more,
        // Blaze
        blaze1: blaze_more,
        // Tiger
        tiger1: tiger_more,
        // Type
        type1: type_more,
        // bac
        bac1: bac_more,
        // dhabi
        dhabi1: dhabi_more,
        // renewery
        renewery1: renewery_more,
        // busy
        busy1: busy_more,
        // mop
        mop1: mop_more,
    },

    MOBILE_FULL: {
        // Soulfood Society
        soulfood1: soulfood1_mobile_full,
        // Round Top
        roundtop1: roundtop_mobile_full_tile,
        // Collection Malou
        malou1: malou_mobile_full_tile,
        // ppl jpg
        ppl1: ppl_mobile_full_tile,
        // friendshipday
        friendshipday1: friend_mobile_full,
        // 000 undefined
        undefined1: undefined_mobile_full,
        // Fred Studios
        fred1: fred_mobile_full_tile,
        // Visual Manipulator
        visual1: 'video/771685735',
        // Janis J Strobl
        janis1: janis_mobile_full_tile,
        // Blaze
        blaze1: blaze_mobile_full,
        // Tiger
        tiger1: tiger_mobile,
        // Type
        type1: type_mobile_full_tile,
        // bac
        bac1: bac_mobile_full_tile,
        // dhabi
        dhabi1: dhabi_mobile_full_tile,
        // renewery
        renewery1: renewery_mobile_full_tile,
        // busy
        busy1: busy_mobile_full_tile,
        // mop
        mop1: mop_mobile_full_tile,
    },

    MOBILE_TILE: {
        // Soulfood Society
        soulfood1: soulfood1_mobile_full,
        // Round Top
        roundtop1: roundtop_mobile_full_tile,
        // Collection Malou
        malou1: malou_mobile_full_tile,
        // ppl jpg
        ppl1: ppl_mobile_full_tile,
        // friendshipday
        friendshipday1: friend_mobile_full,
        // 000 undefined
        undefined1: undefined_mobile_full,
        // Fred Studios
        fred1: fred_mobile_full_tile,
        // Visual Manipulator
        visual1: visual_mobile_full_tile,
        // Janis J Strobl
        janis1: janis_mobile_full_tile,
        // Blaze
        blaze1: blaze_mobile_tile,
        // Tiger
        tiger1: tiger_mobile,
        // Type
        type1: type_mobile_full_tile,
        // bac
        bac1: bac_mobile_full_tile,
        // dhabi
        dhabi1: dhabi_mobile_full_tile,
        // renewery
        renewery1: renewery_mobile_full_tile,
        // busy
        busy1: busy_mobile_full_tile,
        // mop
        mop1: mop_mobile_full_tile,
    },

    MOBILE_DETAIL: {
        // Soulfood Society
        soulfood1: soulfood1_mobile_detail1,
        soulfood2: soulfood1_mobile_detail2,
        soulfood3: soulfood1_mobile_detail3,
        soulfood4: soulfood1_mobile_detail4,
        soulfood5: soulfood1_mobile_detail5,
        // Round Top
        roundtop1: roundtop_mobile_detail1,
        roundtop2: roundtop_mobile_detail2,
        // Collection Malou
        malou1: malou_mobile_detail1,
        malou2: malou_mobile_detail2,
        malou3: malou_mobile_detail3,
        malou4: malou_mobile_detail4,
        // ppl jpg
        ppl1: ppl_detail1,
        ppl2: ppl_detail2,
        // friendshipday
        friendshipday1: friend_detail,
        // 000 undefined
        undefined1: 'video/772426828',
        undefined2: 'video/772535179',
        // Fred Studios
        fred1: fred_desktop_detail1,
        fred2: fred_desktop_detail2,
        fred3: fred_desktop_detail3,
        fred4: 'video/771989571',
        fred5: 'video/771986564',
        fred6: fred_mobile_detail6,
        fred7: fred_desktop_detail7,
        fred8: fred_mobile_detail8,
        fred9: fred_desktop_detail9,
        fred10: fred_desktop_detail10,
        // Visual Manipulator
        visual1: 'video/771685735',
        visual2: visual_mobile_detail2,
        // Janis J Strobl
        janis1: janis_mobile_detail1,
        janis2: janis_mobile_detail2,
        janis3: janis_mobile_detail3,
        janis4: janis_mobile_detail4,
        janis5: janis_mobile_detail5,
        janis6: janis_mobile_detail6,
        janis7: janis_mobile_detail7,
        // Blaze
        blaze1: blaze_mobile_detail1,
        blaze2: blaze_mobile_detail2,
        // Tiger
        tiger1: tiger_detail1,
        tiger2: tiger_detail2,
        // Type
        type1: type_mobile_detail1,
        type2: type_mobile_detail2,
        type3: type_mobile_detail3,
        // bac
        bac1: 'video/772066039',
        bac2: bac_mobile_detail2,
        bac3: bac_mobile_detail3,
        // dhabi
        dhabi1: 'video/773328055',
        dhabi2: 'video/773337390',
        // renewery
        renewery1: renewery_mobile_detail1,
        renewery2: 'video/773284773',
        renewery3: 'video/773284802',
        // busy
        busy1: busy_mobile_detail1,
        busy2: 'video/773736993',
        busy3: busy_desktop_detail2,
        busy4: busy_desktop_detail3,
        busy5: busy_desktop_detail4,
        busy6: busy_desktop_detail5,
        // mop
        mop1: 'video/774761307',
    },

    ABOUT: {
        about: about,
    }
}