import React from 'react';
import { Box } from '@mui/material';
import { useStyles } from './MoreProjectsBoxMobile.styles';
import { IMAGE_MAP } from '../../other/helper/ImgMapper';

export default function MoreProjectsBoxMobile({ project }) {
    
    const styleProps = {
        smallProjectPicture: IMAGE_MAP.DESKTOP_MORE[project.pictures[0]]
    }

    const classes = useStyles(styleProps);

    return (
        <Box py={'0px'} display="flex" className={classes.project_box}>
            <div className={classes.small_project_picture} title={project.customer}></div>            
        </Box>
    )
}