import React, { useContext, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useStyles } from './ProjectDetailsMobile.styles';
import { innerHeightMobile, innerHeightSmall, } from '../../../components/other/helper/MuiStyles';
import { GeneralContext } from '../../../components/other/context/GeneralContext';
import { PROJECTS, FILTERS } from '../../../data/data';
import { IMAGE_MAP } from '../../../components/other/helper/ImgMapper';
import { Link, useParams } from 'react-router-dom';
import MoreProjectsBoxMobile from '../../../components/MoreProjectsBox/mobile/MoreProjectsBoxMobile';
import FooterMobileException from '../../../components/Footer/mobile/FooterMobileException';
// import Imprint from '../../../components/other/dialogs/Imprint';
export default function ProjectDetailsMobile() {

    let { id } = useParams();
    const projectDetail = PROJECTS.find(object => {
        return object.projectId === id;
    });

    const projectPreviewHigher = PROJECTS.filter(object => object.projectId > projectDetail.projectId);
    const projectPreviewLower = PROJECTS.filter(object => object.projectId < projectDetail.projectId);

    const projectPreview = projectPreviewHigher.concat(projectPreviewLower);

    const [
        // selectedImageIndex, 
        setSelectedImageIndex] = useState(0);
    // const [isImageActive, setIsImageActive] = useState(false);
    // const [showMoreProjects, setShowMoreProjects] = useState(false);

    const { isTileViewSelected } = useContext(GeneralContext);

    const styleProps = {
        contentHeight: isTileViewSelected ? innerHeightSmall : innerHeightMobile,
    }

    const classes = useStyles(styleProps);

    let vh = window.innerHeight;
    let vhviewport = vh - 25 - vh * 0.01;
    // let vhviewport = vh - 25;

    window.onresize = function () {
        vh = window.innerHeight;
        vhviewport = vh - 25 - vh * 0.01;
        // vhviewport = vh - 25;
    }
    window.onresize();

    return (
        <Grid item xs={12} display="grid">
            <Box display="flex" flexDirection="column" justifyContent="space-between" height={vhviewport} className={classes.paper} >
                <Box className={classes.project_d_container}>

                    <Box display="flex" flexDirection="column" className={classes.project_picture_container}>
                        <Box display="flex" flexDirection="column" className={classes.project_picture_box}>
                            {projectDetail.pictures?.map((option, index) => {
                                return (
                                    <div key={index}>
                                        {IMAGE_MAP.MOBILE_DETAIL[option]?.includes("video") ?
                                            <div>
                                                <div className={classes.project_video_wrapper}>
                                                    <iframe src={`https://player.vimeo.com/${IMAGE_MAP.MOBILE_DETAIL[option]}?quality=1080p&background=1&dnt=1`}
                                                        className={classes.project_video}
                                                        id={index}
                                                        frameborder="0"
                                                        title="movie"
                                                        allow="autoplay; fullscreen; picture-in-picture"
                                                        dnt="1"
                                                        // background="1"
                                                        // muted="1"
                                                        allowfullscreen>
                                                    </iframe>
                                                </div>
                                                {/* <script src="https://player.vimeo.com/api/player.js"></script> */}
                                            </div>
                                            :
                                            <Box id={index}
                                                // href={`#${index}`} 
                                                // onMouseEnter={() => { setIsImageActive(!isImageActive || isImageActive); setSelectedImageIndex(index) }} 
                                                display="flex" flexDirection="column" justifyContent="center" className={classes.project_picture} style={{ backgroundImage: `url(${IMAGE_MAP.MOBILE_DETAIL[option]})` }}>
                                            </Box>
                                        }
                                    </div>
                                )
                            })}
                        </Box>

                        {/* PICTURE POSITION INDICATOR */}
                        <Box display="flex" flexDirection="column" className={classes.indication_container}>
                            {projectDetail.pictures?.map((option, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {IMAGE_MAP.MOBILE_DETAIL[option]?.includes("video") ?

                                            <a href={`#${index}`} onClick={() => setSelectedImageIndex(index)} key={index} >
                                                <Box position="relative">
                                                    <div className={classes.video_overlay}></div>
                                                    <div>
                                                        <div className={classes.picture_box}>

                                                            <iframe src={`https://player.vimeo.com/${IMAGE_MAP.MOBILE_DETAIL[option]}?quality=1080p&background=1&dnt=1`}
                                                                className={classes.mini_project_video}
                                                                id={index}
                                                                frameborder="0"
                                                                title="movie"
                                                                allow="autoplay; fullscreen; picture-in-picture"

                                                                allowfullscreen>
                                                            </iframe>
                                                        </div>

                                                        {/* <script src="https://player.vimeo.com/api/player.js"></script> */}
                                                    </div>
                                                </Box>
                                            </a>

                                            :
                                            <a href={`#${index}`} onClick={() => setSelectedImageIndex(index)} key={index} className={classes.picture_box} style={{ backgroundImage: `url(${IMAGE_MAP.MOBILE_DETAIL[option]})` }}>
                                            </a>
                                        }
                                    </React.Fragment>
                                )
                            })}
                        </Box>
                    </Box>

                    <Box px={'20px'} className={classes.project_d_wrapper}>
                        <Box pt={'20px'} display="flex" flexDirection="row" justifyContent={"space-between"}>
                            <Box display="flex" flexDirection="column">
                                {/* CUSTOMER */}
                                <Typography variant="h1">
                                    {projectDetail.customer ? projectDetail.customer + ',' : ''}
                                </Typography>
                                {/* LOCATION */}
                                <Typography variant="h1">
                                    {projectDetail.location}
                                </Typography>
                            </Box>

                            {/* YEAR */}
                            <Typography variant="h1">
                                {projectDetail.year}
                            </Typography>
                        </Box>

                        {projectDetail.description &&
                            <Typography variant="body1" style={{ paddingTop: '20px' }}>
                                {projectDetail.description}
                            </Typography>
                        }

                        {/* COLLABORATORS */}
                        {projectDetail.collaborators &&
                            <Box pt={'20px'}>
                                {projectDetail.collaborators?.map((option, index) => {
                                    return (
                                        <Typography key={index} variant="body1">
                                            {option.role}: {option.name}
                                        </Typography>
                                    )
                                })}

                            </Box>
                        }



                        {/* TYPES */}
                        <Box py={'20px'} display="flex" flexWrap="wrap">
                            {projectDetail.type?.map((option, index) => {
                                return (
                                    // <Button
                                    //     disableRipple
                                    //     key={index}
                                    //     name={option}
                                    //     variant="outlined"
                                    //     style={{ marginRight: '10px', marginBottom: '10px' }}
                                    //     position="relative"
                                    // >
                                    //     {FILTERS[option - 1].name}{index === projectDetail.type.length - 1 ? '' : ','}
                                    // </Button>
                                    <Typography key={index} variant="body1" style={{ marginRight: '10px', marginBottom: '10px' }} className={classes.project_type_tag}>
                                        {FILTERS[option - 1].name}
                                    </Typography>
                                )
                            })}
                        </Box>
                    </Box>

                    {/* MORE PROJECTS */}
                    <Box >
                        <Typography px={'20px'} pb={'10px'} variant="body1">
                            more projects
                        </Typography>
                        <Box display="flex">
                            {projectPreview.map((option, index) => {
                                return (
                                    <Box key={index} display="flex">
                                        {index < 5 &&
                                            <Link to={`/project/${option.projectId}`} key={index}>
                                                <MoreProjectsBoxMobile key={index} project={option} />
                                            </Link>
                                        }
                                    </Box>
                                )
                            })}
                        </Box>
                        {/* FOOTER MOBILE */}
                        <FooterMobileException />
                    </Box>
                </Box>
            </Box>
        </Grid >

    )
}