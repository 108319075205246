import React, { useContext, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './TileProjectBox.styles';
import { IMAGE_MAP } from '../../other/helper/ImgMapper';
import { GeneralContext } from '../../other/context/GeneralContext';

export default function TileProjectBox({ project }) {

    const { isFilterSet } = useContext(GeneralContext);
    const ref = useRef(null);
    const [availableWidthCustomer, setAvailableWidthCustomer] = useState(0);

    let styleProps = {
        availableWidthCustomer: availableWidthCustomer+'px',
    }

    window.requestAnimationFrame(function () {
        setAvailableWidthCustomer(ref.current?.clientWidth - 30 - 24);
    });

    const classes = useStyles(styleProps);

    return (
        <Box pb={isFilterSet ? "39px" : 0} pl={'15px'} display="flex" className={classes.project_box} >
            <Box className={classes.tile_container}>
                <img className={classes.tile_view_picture} src={IMAGE_MAP.DESKTOP_TILE[project.pictures[0]]} alt={project.customer} />
                 <Box ref={ref} display="flex" alignItems="flex-end" justifyContent="space-between" className={classes.tile_overlay} title={project.customer}>
                    <Box pl='8px' display="flex">
                        <Typography variant="body2" className={classes.customer}>
                            {project.customer}
                        </Typography>
                    </Box>
                    <Box px='8px' display="flex" justifyContent="flex-end" className={classes.year}>
                        <Typography variant="body2">
                            {project.year}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}