import makeStyles from "@mui/styles/makeStyles";
import { brown } from "../../../components/other/helper/MuiStyles";

export const useStyles = makeStyles(() => ({    
    paper: {
        backgroundColor: brown,
        },
        
    about_img: {
        position: 'absolute',
        top: '25vh',
        left: '50vw',
        maxWidth: '20vw',
        zIndex: '5',
        transform: 'translate(-50%,-50%)'
    },

    text_box: {
        position: 'absolute',
        bottom: '40px',
    }

}));