import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(() => ({  
    button_carla:{
        padding: '0',
        '&:hover': {
            fontStyle: 'normal',
        },
        '&:focus': {
            fontStyle: 'normal',
        },
        '&:active': {
            fontStyle: 'normal',
        },
    },
    button_group: {
        marginLeft: '0px'
    },  
    header_box: {
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: '100vw',
        minWidth: 'calc(100vw - 40px)',
        flexDirection: 'column',
        backgroundColor: props => props.headerBackgroundColor,
        // this has to be lineHeight + paddingBottom of small Header
        // height: '10vh',
        // transition: 'padding-top 0.04s ease',
        // transitionDelay: '0.5s'
    },

    top_header_box: {
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: '100vw',
        minWidth: 'calc(100vw - 40px)'
    },

    sub_nav_box: {
        marginLeft: '0px',
        display: 'flex',
        alignItems: 'start',
        paddingBottom: '5px',
    },

}));