import React, { useContext } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Imprint from '../../other/dialogs/Imprint';
import { useLocation } from 'react-router-dom';
import { brown } from '../../other/helper/MuiStyles';
import { GeneralContext } from '../../other/context/GeneralContext';
import { useStyles } from '../desktop/Footer.styles';

export default function FooterMobile() {

        const { cookiesAccepted, setCookiesAccepted } = useContext(GeneralContext);
        const location = useLocation();
        const classes = useStyles();

        return (

                <>
                        {!(location.pathname.includes("project")) &&
                                <>

                                        {!cookiesAccepted &&
                                                <Box p={3} display="flex" alignItems="start" justifyContent="space-between" style={{ position: 'absolute', bottom: '0', left: '0', width: 'calc(100vw - 48px)', height: 'auto', backgroundColor: 'white' }}>
                                                        <Box pb={'20px'} width="70vw">
                                                                <Typography variant="body1">
                                                                        This website or its third-party tools use cookies, which are necessary for its functioning and required to achieve the purposes illustrated in the
                                                                        cookie policy.
                                                                        You accept the use of cookies by closing or dismissing this notice, by scrolling this page, by clicking a link or button or by continuing to browse
                                                                        otherwise.
                                                                </Typography>
                                                                <Imprint buttonName={"Learn more"} />
                                                        </Box>
                                                        <Button
                                                                disableRipple
                                                                onClick={() => setCookiesAccepted(true)}
                                                                className={classes.link}
                                                        >
                                                                accept
                                                        </Button>
                                                </Box>
                                        }
                                        <Box px={'21px'} display="flex" alignItems="center" bottom="0" minWidth="calc(100vw - 42px)" justifyContent="space-between" style={{ height: '25px', position: 'absolute', bottom: '0', backgroundColor: location.pathname === '/about' ? brown : '' }}>
                                                <Typography variant="body1">© 2024</Typography>
                                                <Imprint buttonName={"imprint"} />
                                        </Box>
                                </>
                        }
                </>
        )
}