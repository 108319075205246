import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(() => ({  
    button_carla:{
        padding: '0',
        '&:hover': {
            fontStyle: 'normal',
        },
        '&:focus': {
            fontStyle: 'normal',
        },
        '&:active': {
            fontStyle: 'normal',
        },
    },
    button_group: {
        marginLeft: '50px'
    },    
    header_box: {
        maxWidth: '100vw',
        backgroundColor: props => props.headerBackgroundColor,
        // this has to be lineHeight + paddingBottom of small Header
        height: '39px',
    },
}));